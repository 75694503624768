import React, { useState } from "react";
import Header from "../Header";
import Footer from "../Footer";

export default function LocalAgent() {
  const [isAgents, setIsAgents] = useState(true);

  const agentsHandler = () => {
    setIsAgents(true);
  };
  const officeHandler = () => {
    setIsAgents(false);
  };

  return (
    <>
      <Header />
      <main>
        <div className="p-10 text-center text-textColor">
          <h2 className="text-[3.3vmin] my-4 font-medium">
            Listings at Golden Gates
          </h2>
          <div className="flex justify-center gap-10 my-10 py-10">
            <button
              className="text-[6vmin] flex gap-3 border-b-2 border-primaryColor py-3 hover:border-b-[10px] duration-500 ease-in"
              onClick={agentsHandler}
            >
              <i class="ri-user-3-line"></i>Agents
            </button>
            <button
              className=" text-[6vmin] flex gap-3 border-b-2 border-primaryColor py-3 hover:border-b-[10px] duration-500 ease-in"
              onClick={officeHandler}
            >
              <i class="ri-home-office-line"></i>Office
            </button>
          </div>
          <div className="">
            {isAgents ? (
              <div className="md:flex md:flex-col-2 border-t-[1px] border-b-[1px] border-textColor">
                <div className="md:w-1/4 md:border-r-[1px] border-textColor flex justify-center items-center py-5 border-b-[1px] md:border-b-[0px]">
                  <h1 className="uppercase">Find an Agent</h1>
                </div>
                <div className="md:w-3/4 flex flex-col">
                  <div className="flex flex-row py-4 md:px-4 gap-3 w-full border-b-[1px] border-textColor">
                    <input
                      type="text"
                      placeholder="Enter suburb or postcode"
                      className="rounded-full bg-baseColor border border-textColor px-6 py-3 text-[2.4vmin] w-2/3"
                    />
                    <select
                      name="radius"
                      id="radius_agent"
                      className="rounded-full bg-baseColor border border-textColor hover:bg-textColor hover:text-white ease-in duration-300 px-3 py-3 text-[2.4vmin] w-1/3"
                    >
                      <option value="fivekm">Within 5 km</option>
                      <option value="tenkm" selected>
                        Within 10 km
                      </option>
                      <option value="twentykm">Within 20 km</option>
                      <option value="thirtykm">Within 30 km</option>
                    </select>
                  </div>
                  <div className="flex flex-row py-4 md:px-4 gap-3 w-full">
                    <input
                      type="text"
                      placeholder="First Name"
                      className="rounded-full bg-baseColor border border-textColor px-6 py-3 text-[2.4vmin] w-1/3"
                    />
                    <input
                      type="text"
                      placeholder="Last Name"
                      className="rounded-full bg-baseColor border border-textColor px-6 py-3 text-[2.4vmin] w-1/3"
                    />
                    <input
                      type="button"
                      value="Search"
                      className="w-1/3 border border-textColor rounded-full cursor-pointer bg-textColor text-white hover:bg-baseColor hover:text-textColor duration-300 ease-in"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="md:flex md:flex-col-2 border-t-[1px] border-b-[1px] border-textColor">
                <div className="md:w-1/4 md:border-r-[1px] border-textColor flex justify-center items-center py-5 border-b-[1px] md:border-b-[0px]">
                  <h1 className="uppercase">Find an Office</h1>
                </div>
                <div className="md:w-3/4 flex flex-col">
                  <div className="flex flex-row py-4 md:px-4 gap-3 w-full ">
                    <input
                      type="text"
                      placeholder="Im seeking for an office around..."
                      className="rounded-full bg-baseColor border border-textColor px-6 py-3 text-[2.4vmin] w-2/3"
                    />
                    <select
                      name="radius"
                      id="radius_agent"
                      className="rounded-full bg-baseColor border border-textColor hover:bg-textColor hover:text-white ease-in duration-300 px-3 py-3 text-[2.4vmin] w-1/3"
                    >
                      <option value="fivekm">Within 5 km</option>
                      <option value="tenkm" selected>
                        Within 10 km
                      </option>
                      <option value="twentykm">Within 20 km</option>
                      <option value="thirtykm">Within 30 km</option>
                    </select>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
