import React from "react";
import Header from "../Header";
import Footer from "../Footer";

import video2 from "../../assets/monochrome.mp4";

export default function Jointhefamily() {
  return (
    <>
      <Header />
      <main>
        <div className="container text-textColor">
          <div className="md:flex md:flex-col-3 py-6">
            <div className="w-1/3">
              <h1 className="text-textColor text-[6vmin] ">Join the family</h1>
            </div>
            <div className="w-1/3"></div>
            <div className="w-1/3 md:text-right text-textColor text-[2.4vmin] mt-5 md:mt-10">
              Growth - it's an integral part of our business.
            </div>
          </div>
          <div className="md:flex md:flex-col-2 py-10">
            <div className="md:w-1/2 h-[20rem] bg-[url('https://assets.raywhite.com/eyJidWNrZXQiOiJyYXl3aGl0ZS1zaW1wbGUtcHJkIiwia2V5IjoiYXNzZXRzL3ZvbHVtZXMvaW1hZ2VzL0JyYW5kLWltYWdlcy9FbGlzZUhhc3NleV9SYXlXaGl0ZTIzXzEyNjEuanBnIiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjoxMjgwLCJoZWlnaHQiOjY0MCwiZml0IjoiY292ZXIifSwid2l0aE1ldGFkYXRhIjp0cnVlLCJ0b0Zvcm1hdCI6ImpwZWciLCJqcGVnIjp7InF1YWxpdHkiOjgwLCJwcm9ncmVzc2l2ZSI6ZmFsc2UsIm9wdGltaXNlQ29kaW5nIjp0cnVlLCJ0cmVsbGlzUXVhbnRpc2F0aW9uIjp0cnVlLCJvdmVyc2hvb3REZXJpbmdpbmciOnRydWV9fX0=')] bg-cover bg-center"></div>
            <div className="md:w-1/2 flex flex-col gap-5 text-[2.2vmin] px-8">
              <h1 className="text-[5vmin]">
                Business Opportunities and Careers
              </h1>
              <p>
                We love the real estate industry. We serve the vital needs of
                our communities, every customer is unique, we work in teams, and
                our success is not limited.
              </p>
              <p>
                Ray White’s ambition over 120 years has been to provide the best
                place for our members to build a remarkable career in real
                estate, by challenging them to chase their potential and achieve
                things they might not have thought possible.{" "}
              </p>
              <p>
                Interested in a career in real estate with us? Whether you are
                interested in residential, commercial, or rural real estate we
                would welcome the opportunity to discuss you interest further.
                There are many different career paths available, including
                business ownership, sales, property management or
                administration.{" "}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center py-10">
            <div className="md:w-2/3 bg-[#EFEEE3] text-center flex flex-col gap-4 text-[2.2vmin] p-10">
              <h1 className="text-[5vmin] mb-4">
                Ray White Business Opportunities
              </h1>
              <p>
                Leadership is one of our most important words because success
                has been built upon the quality of leadership in every Ray White
                office. Every office reflects the personality and ambition of
                its business leader (the franchisee), and the success of their
                people is determined by the leadership they provide.
              </p>
              <p>
                We support our business owners in all aspects of their
                leadership journey, from the start-up phase, through to growth
                and expansion, and handling succession issues. The needs of our
                business owners change significantly in all of these stages, and
                we have specialist teams comprising over 300 corporate members
                that can ask the right questions and challenge our business
                owners to find the best solutions.
              </p>
              <p>
                There is no set formula or criteria for us to follow to
                determine who will become a successful Ray White business owner.
                They will generally need to already have had a successful real
                estate career in sales or property management, and most
                importantly they have the ambition to build a business of which
                they can be proud.
              </p>
              <p>
                For a confidential discussion with one of our corporate leaders
                about the potential of becoming a Ray White business owner,
                <span className="underline cursor-pointer">
                  please see our Markets.
                </span>
              </p>
              <h1 className="text-[5vmin] mb-4 mt-4">Real Estate Careers</h1>
              <p>
                When it comes to us defining how we plan to support and add
                value to everyone in the Ray White family, we like the phrase of
                us seeking to be a springboard. The harder you jump on a
                springboard the higher you will go. The same goes with us. What
                we seek to provide is more than just support. It’s more than a
                brand. It's a two-way relationship, whereby if you push harder
                you can go higher. We respond to ambition.
              </p>
              <p>
                We welcome anyone that seeks to build a great career in real
                estate and who want to use our springboard to reach their
                potential. This is every sector of the real estate market -
                residential, commercial and rural - and every role in the
                industry - business ownership, sales, property management and
                administration.
              </p>
              <p>
                If you're already a successful member of the real estate
                industry, we love to challenge ourselves to ensure our
                springboard can help you reach even greater heights. We know
                that people with ambition can be successful in any group, but
                our commitment is to do all we can to ensure you will achieve
                more as part of our group.
              </p>
              <p>
                See{" "}
                <span className="underline cursor-pointer">
                  what we offer to our sales agents
                </span>{" "}
                to understand how our springboard can be of value to you.
              </p>
            </div>
          </div>
          <div className="w-full h-[400px] md:h-[600px] bg-black flex items-center justify-center mb-10">
            <video
              src={video2}
              autoPlay
              loop
              className="relative w-full h-full object-fill"
            ></video>
            <button className=" absolute w-[250px] h-[250px] md:w-[350px] md:h-[350px] text-[5vmin] text-white bg-transparent hover:bg-primaryColor duration-500 eas hover:text-black rounded-full bg-primaryColor">
              Play
            </button>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
