import "./App.css";
import Aos from "aos";
import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/pages/Home";
import Jointhefamily from "./components/pages/Jointhefamily";
import LocalAgent from "./components/pages/LocalAgent";
import Properties from "./components/pages/Properties";

function App() {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/jointhefamily" element={<Jointhefamily />} />
        <Route path="/localagent" element={<LocalAgent />} />
        <Route path="/properties" element={<Properties />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
