import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  const menu = !isOpen ? (
    <>
      Menu <i class="ri-menu-3-fill"></i>
    </>
  ) : (
    <i class="ri-close-large-fill"></i>
  );
  return (
    <header>
      <div className="container">
        <div className="w-full h-28 leading-[40px] flex items-center justify-between top-0 mx-auto">
          {/* Logo  Start*/}
          <div className="flex h-full justify-center items-end bg-primaryColor px-3 ">
            <Link to="/">
              <h2 className="font-[600] text-[1.1rem] ">
                GOLDEN GATE PROPERTIES.
              </h2>
            </Link>
          </div>
          {/* Logo END */}
          {/* Links Start */}
          <ul className="hidden md:flex  justify-center gap-6 text-textColor">
            <li>
              <Link
                className="flex gap-3 uppercase text-[2.2vmin]"
                to="/jointhefamily"
              >
                Join the family <i class="ri-door-open-line"></i>
              </Link>
            </li>
            <li>
              <Link
                className="flex gap-3 uppercase text-[2.2vmin]"
                to="/localagent"
              >
                Find Local Agent
                <i class="ri-user-search-fill"></i>
              </Link>
            </li>
            <li>
              <Link
                className="flex gap-3 uppercase text-[2.2vmin]"
                to="/properties"
              >
                Find Properties
                <i class="ri-home-3-line"></i>
              </Link>
            </li>
          </ul>
          {/* Links End */}
          {/* Menu Buttun Start*/}
          <div className="md:hidden flex justify-center items-center p-3 ">
            <button
              onClick={toggleNavbar}
              className="text-textColor flex gap-2 rounded-full bg-white hover:bg-textColor hover:text-white px-5 ease-in duration-300"
            >
              {menu}
            </button>
          </div>

          {/* Menu Buttun ENd*/}
        </div>
        {isOpen && (
          <ul
            data-aos="zoom-in"
            data-aos-duration="1500"
            className="z-10 flex flex-col justify-center items-center p-3 gap-4"
          >
            <li>
              <Link
                className="flex uppercase text-[3.2vmin] px-4 py-2 rounded-full hover:bg-[#c8c7c2] duration-500 ease-in"
                to="/jointhefamily"
              >
                Join the family
              </Link>
            </li>
            <li>
              <Link
                className="flex uppercase text-[3.2vmin] px-4 py-2 rounded-full hover:bg-[#c8c7c2] duration-500 ease-in"
                to="/localagent"
              >
                Find Local Agent
              </Link>
            </li>
            <li>
              <Link
                className="flex uppercase text-[3.2vmin] px-4 py-2 rounded-full hover:bg-[#c8c7c2] duration-500 ease-in"
                to="properties"
              >
                Find Properties
              </Link>
            </li>
          </ul>
        )}
      </div>
    </header>
  );
}
