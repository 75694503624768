import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className=" bg-baseColor  text-textColor py-8 border-t-[1px] border-textColor">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div className="flex flex-col space-y-4 md:border-r-[1px] border-textColor">
            <h2 className="text-lg font-bold">Golden Gate Properties</h2>
            <p>
              Join the family at Golden Gate, and unlock your career potential.
            </p>
            <div>
              <button className="hover:bg-textColor hover:text-white duration-500 ease-in px-4 py-2 rounded-full mt-10">
                Join the family
              </button>
            </div>
          </div>
          <div className="flex flex-col space-y-4">
            <h2 className="text-lg font-bold">Browse</h2>
            <Link to="/" className=" hover:text-gray-400">
              Home
            </Link>
            <Link to="/jointhefamily" className=" hover:text-gray-400">
              Join the family
            </Link>
            <Link to="/localagent" className=" hover:text-gray-400">
              Find Local Agent
            </Link>
            <Link to="/properties" className=" hover:text-gray-400">
              Find Properties
            </Link>
          </div>
          <div className="flex flex-col space-y-4">
            <h2 className="text-lg font-bold">Terms</h2>
            <p>Legal information</p>

            <p>Franchisor privacy policy</p>
          </div>
          <div className="flex flex-col space-y-4">
            <h2 className="text-lg font-bold">Social Media</h2>
            <div className="flex flex-col gap-2">
              <a
                href="/"
                className="flex gap-3 px-2 py-1 hover:bg-textColor hover:text-white  rounded-3xl duration-500 ease-in"
              >
                <i class="ri-facebook-fill"></i>
                <p>Facebook</p>
              </a>
              <a
                href="/"
                className="flex gap-3 px-2 py-1 hover:bg-textColor hover:text-white rounded-3xl duration-500 ease-in"
              >
                <i class="ri-twitter-x-line"></i>
                <p>Twitter</p>
              </a>

              <a
                href="/"
                className="flex gap-3 px-2 py-1 hover:bg-textColor hover:text-white rounded-3xl duration-500 ease-in"
              >
                <i class="ri-instagram-line"></i>
                <p>Instagram</p>
              </a>
              <a
                href="/"
                className="flex gap-3 px-2 py-1 hover:bg-textColor hover:text-white rounded-3xl duration-500 ease-in"
              >
                <i class="ri-linkedin-fill"></i>
                <p>LinkedIn</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
