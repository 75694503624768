import React, { useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import defaultbuy from "../../assets/default_buy.jpg";
import defaultrent from "../../assets/default_rent.jpg";
import defaultsold from "../../assets/default_sold.jpg";

export default function Properties() {
  const [pform, setPform] = useState("buy");

  return (
    <>
      <Header />
      <main>
        <div className=" flex items-center justify-center gap-16 text-textColor bg-white">
          <button
            className="flex py-2 my-5 gap-4 text-[6vmin] border-b-4 border-primaryColor hover:border-b-[15px]  duration-500 ease-in"
            onClick={() => setPform("buy")}
          >
            <i className="ri-auction-line"></i>Buy
          </button>
          <button
            className=" flex py-2 my-5 gap-4 text-[6vmin] border-b-4 border-primaryColor hover:border-b-[15px] duration-500 ease-in"
            onClick={() => setPform("rent")}
          >
            <i className="ri-building-2-line"></i>Rent
          </button>
          <button
            className=" flex py-2 my-5 gap-4 text-[6vmin] border-b-4 border-primaryColor hover:border-b-[15px] duration-500 ease-in"
            onClick={() => setPform("sold")}
          >
            <i className="ri-thumb-up-line"></i>Sold
          </button>
        </div>
        <div className="flex px-6 text-textColor border-[#9f9f9f] border-t-[1px] ">
          <div className="hidden md:w-1/3 md:bg-[#dddacd] md:p-6 md:flex md:flex-col md:gap-5 items-center">
            {pform === "buy" && (
              <>
                <h2>SEARCH OVER 5,000 PROPERTIES</h2>
                <img
                  src={defaultbuy}
                  alt="Buy"
                  className=" rounded-full opacity-60"
                />
              </>
            )}

            {pform === "rent" && (
              <>
                <h2>YOUR RENTAL IS WAITING</h2>
                <img
                  src={defaultrent}
                  alt="Rent"
                  className=" rounded-full opacity-60"
                />
              </>
            )}

            {pform === "sold" && (
              <>
                {" "}
                <h2>SEE OUR PAST RESULTS</h2>
                <img
                  src={defaultsold}
                  alt="Sold"
                  className=" rounded-full opacity-60"
                />
              </>
            )}
          </div>
          <div className="w-full md:w-2/3 flex flex-col p-4 bg-white">
            <h1 className="text-[3.3vmin]">
              {pform === "buy" && <>Let’s find your perfect home</>}
              {pform === "rent" && <>Let’s find your perfect rental</>}
              {pform === "sold" && (
                <>Browse thousands of sold properties across Australia</>
              )}
            </h1>
            <h2 className="my-5 text-[2vmin] text-[#c0c0c0]">
              WHAT'S YOUR BUDGET RANGE ?
            </h2>
            <input
              type="range"
              min="0"
              max="1000000"
              className="w-full h-[2px] text-textColor mt-10 mb-2 accent-zinc-800"
            />
            <div className="flex mb-10 text-[2vmin] text-[#c0c0c0] justify-between">
              <label>$0</label>
              <label>$10m+</label>
            </div>
            <div className=" flex flex-col">
              <div className="flex flex-row py-4 md:px-4 gap-3 w-full border-b-[1px] border-textColor">
                <input
                  type="text"
                  placeholder="Enter suburb or postcode"
                  className="rounded-full bg-white border border-textColor px-6 py-3 text-[2.4vmin] w-2/3"
                />
                <select
                  name="radius"
                  id="radius_agent"
                  className="rounded-full bg-white border border-textColor hover:bg-textColor hover:text-white ease-in duration-300 px-3 py-3 text-[2.4vmin] w-1/3"
                >
                  <option value="fivekm">Within 5 km</option>
                  <option value="tenkm" selected>
                    Within 10 km
                  </option>
                  <option value="twentykm">Within 20 km</option>
                  <option value="thirtykm">Within 30 km</option>
                </select>
              </div>
              <div className="flex flex-col py-4 md:flex-row md:px-4 gap-3 w-full">
                <select
                  name="radius"
                  id="radius_agent"
                  className="rounded-full bg-white border border-textColor hover:bg-textColor hover:text-white ease-in duration-300 px-3 py-3 text-[2.4vmin] "
                >
                  <option value="fivekm" selected>
                    All Properties
                  </option>
                  <option value="tenkm">Acreage</option>
                  <option value="twentykm">Apline</option>
                  <option value="thirtykm">Apartment</option>
                  <option value="thirtykm">Flat</option>
                  <option value="thirtykm">House</option>
                  <option value="thirtykm">Studio</option>
                  <option value="thirtykm">Land</option>
                </select>
                <select
                  name="radius"
                  id="radius_agent"
                  className="rounded-full bg-white border border-textColor hover:bg-textColor hover:text-white ease-in duration-300 px-3 py-3 text-[2.4vmin] "
                >
                  <option value="fivekm" selected>
                    Bedrooms
                  </option>
                  <option value="tenkm">+1 Bedrooms</option>
                  <option value="twentykm">+2 Bedrooms</option>
                  <option value="thirtykm">+3 Bedrooms</option>
                  <option value="thirtykm">+4 Bedrooms</option>
                </select>
                <select
                  name="radius"
                  id="radius_agent"
                  className="rounded-full bg-white border border-textColor hover:bg-textColor hover:text-white ease-in duration-300 px-3 py-3 text-[2.4vmin]"
                >
                  <option value="fivekm" selected>
                    Bathrooms
                  </option>
                  <option value="tenkm">+1 Bathrooms</option>
                  <option value="twentykm">+2 Bathrooms</option>
                  <option value="thirtykm">+3 Bathrooms</option>
                  <option value="thirtykm">+4 Bathrooms</option>
                </select>
                <select
                  name="radius"
                  id="radius_agent"
                  className="rounded-full bg-white border border-textColor hover:bg-textColor hover:text-white ease-in duration-300 px-3 py-3 text-[2.4vmin] "
                >
                  <option value="fivekm" selected>
                    Parking
                  </option>
                  <option value="tenkm">+1 Parking</option>
                  <option value="twentykm">+2 Parking</option>
                  <option value="thirtykm">+3 Parking</option>
                  <option value="thirtykm">+4 Parking</option>
                </select>

                <button className="w-1/3 border border-textColor rounded-full cursor-pointer bg-textColor text-white hover:bg-baseColor hover:text-textColor duration-300 ease-in">
                  Search <i className="ri-search-line"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
