import React from "react";
import Header from "../Header";
import Footer from "../Footer";

import video1 from "../../assets/video1.mp4";
import imgResidential from "../../assets/Residential.jpg";
import imgCommercial from "../../assets/Commercial.jpg";
import imgRural from "../../assets/Rurals.jpg";

export default function Home() {
  return (
    <>
      <Header />
      <main>
        <div className="md:hidden w-full h-[500px] bg-cover bg-center bg-[url('https://assets.raywhite.com/eyJidWNrZXQiOiJyYXl3aGl0ZS1zaW1wbGUtcHJkIiwia2V5IjoiYXNzZXRzL3ZvbHVtZXMvaW1hZ2VzL0JyYW5kLWltYWdlcy9FbGlzZUhhc3NleV9SYXlXaGl0ZTIzXzEwMTIuanBnIiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjo2MDAsImhlaWdodCI6ODI2LCJmaXQiOiJjb3ZlciJ9LCJ3aXRoTWV0YWRhdGEiOnRydWUsInRvRm9ybWF0IjoianBlZyIsImpwZWciOnsicXVhbGl0eSI6ODAsInByb2dyZXNzaXZlIjpmYWxzZSwib3B0aW1pc2VDb2RpbmciOnRydWUsInRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZX19fQ==')]"></div>
        <div className=" md:flex pb-16">
          <div className="container md:w-1/3  py-5">
            <h3 className="text-textColor text-[2.3vmin] font-medium tracking-wider">
              OPPORTUNITY KNOCKS
            </h3>
            <div className="flex flex-col gap-5 m-5">
              <div className="flex border-b-2 border-textColor ">
                <h2 className="text-textColor text-[5vmin] hover:scale-105 ease-in duration-500">
                  <i class="ri-team-fill"></i> Further together
                </h2>
              </div>
              <div className="flex border-b-2 border-textColor">
                <h2 className="text-textColor text-[5vmin] hover:scale-105 ease-in duration-500">
                  <i class="ri-store-2-line"></i> Market insights
                </h2>
              </div>
              <div className="flex border-b-2 border-textColor">
                <h2 className="text-textColor text-[5vmin] hover:scale-105 ease-in duration-500">
                  <i class="ri-home-6-line"></i> Sell your property
                </h2>
              </div>
            </div>
            <div className="text-textColor py-10 text-[2vmin] w-2/3 tracking-wider font-medium">
              <p>
                Join Australasia's leading real estate group, with nearly 12,000
                property professionals and 1,000 businesses across 11 countries.
              </p>
            </div>
          </div>
          <div className="hidden md:flex md:w-2/3 bg-slate-200">
            <video autoPlay muted loop>
              <source src={video1} typeof="video/mp4" />
            </video>
          </div>
        </div>
        <div className="bg-[#EFEEE3] flex flex-col gap-6 text-textColor text-center justify-center items-center py-10">
          <h1 className="text-[6vmin]">Explore the Golden Gate Group</h1>
          <p className="text-[2vmin] w-1/3">
            Ray White is a household name in Australasia, a fourth-generation
            family business that seeks to be proud of every transaction and
            every relationship.
          </p>
          <button className="text-[2.4vmin] uppercase px-3 ">
            About Us <i class="ri-arrow-right-line"></i>
          </button>
        </div>
        <div
          className="container md:flex md:flex-col-3 bg-[#EFEEE3] pb-10"
          data-aos="fade-up"
          data-aos-duration="500"
        >
          <div className="text-white text-[5vmin] w-full  h-[450px] flex justify-center items-end overflow-hidden">
            <img
              src={imgResidential}
              alt="ResidentialIMg"
              className="relative hover:scale-110 hover:opacity-55 duration-500 ease-in-out"
            />
            <h1 className="z-10 absolute mb-10">Residential</h1>
          </div>
          <div className="text-white text-[5vmin] w-full  h-[450px] flex justify-center items-end overflow-hidden">
            <img
              src={imgCommercial}
              alt="ResidentialIMg"
              className="relative  hover:scale-110 hover:opacity-55 duration-500 ease-in-out"
            />
            <h1 className="z-10 absolute mb-10">Commercial</h1>
          </div>
          <div className="text-white text-[5vmin] w-full  h-[450px] flex justify-center items-end overflow-hidden">
            <img
              src={imgRural}
              alt="ResidentialIMg"
              className="relative  hover:scale-110 hover:opacity-55 duration-500 ease-in-out"
            />
            <h1 className="z-10 absolute mb-10">Rural & Livestock</h1>
          </div>
        </div>
        <div className="container md:flex py-16 bg-baseColor">
          <div className="md:w-1/4 ">
            <div className="bg-primaryColor selection:bg-white flex flex-col h-64 text-textColor py-2  ">
              <h3 className="basis-3/5 text-center">
                GET THE LATEST FROM OUR TEAM
              </h3>
              <h1 className="basis-2/5 text-[5vmin] pl-3 pr-28">
                Sign up to receive our latest updates
              </h1>
            </div>
          </div>
          <div className="md:w-3/4 mx-auto bg-white px-12 py-10">
            <form className="grid grid-flow-row gap-10" action="#">
              <div className="grid grid-cols-2 gap-5">
                <div className="grid grid-flow-row gap-3">
                  <label className="text-textColor uppercase text-[2.2vmin] font-semibold">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="border rounded-full text-textColor py-1 px-3 border-textColor w-full"
                    required
                    placeholder="e.g. Smith"
                  />
                </div>
                <div className="grid grid-flow-row gap-3">
                  <label className="text-textColor uppercase text-[2.2vmin] font-semibold">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="border rounded-full text-textColor py-1 px-3 border-textColor w-full"
                    required
                    placeholder="e.g Davies"
                  />
                </div>
              </div>
              <div className="grid grid-flow-row gap-3">
                <label className="text-textColor uppercase text-[2.2vmin] font-semibold">
                  Email
                </label>
                <input
                  type="email"
                  className="border rounded-full text-textColor py-1 px-3 border-textColor"
                  required
                  placeholder="e.g smith@email.com"
                />
              </div>
              <div className="grid grid-flow-row">
                <button className="w-20 bg-textColor text-white py-1 px-3 rounded-full text-[2.2vmin] hover:bg-primaryColor duration-300 ease-in hover:text-textColor ">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
